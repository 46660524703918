'use strict';

angular.module('webmailWrapper')
    .controller('ChangePasswordCtrl', function($scope, mailboxModel, alertManager, emailAddress) {
        $scope.emailAddress = emailAddress;
        $scope.data = {
            oldPassword: '',
            password: '',
            passwordRepeat: ''
        };

        $scope.submit = function(form) {
            $scope.$broadcast('show-errors-check-validity');
            if (form.$invalid) {
                return;
            }

            mailboxModel.updateSecret($scope.data.oldPassword, $scope.data.password)
                .then(function() {
                    alertManager.success('Passwort wird geändert.');
                    $scope.data.oldPassword = '';
                    $scope.data.password = '';
                    $scope.data.passwordRepeat = '';
                }, function() {
                    alertManager.error('Passwort konnte nicht geändert werden.');
                });
        };
    });
