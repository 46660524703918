'use strict';

angular
    .module('webmailWrapper')
    .directive('paginationLimit', function () {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: require('@/views/directive/paginationLimit.html')
        };
    });
