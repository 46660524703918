'use strict';

angular
    .module('webmailWrapper')
    .directive('toggleAll', function () {
        return {
            restrict: 'E',
            scope: {
                selected: '=',
                data: '=',
                trackBy: '@',
                change: '&'
            },
            template: '<input type="checkbox" ng-checked="allSelected(data)" ng-click="toggleAll()" />',
            compile: function (elem, attrs) {
                if (!attrs.trackBy) {
                    attrs.trackBy = 'id';
                }
            },
            controller: function ($scope) {
                function countSelected(count, obj) {
                    return count + ($scope.selected[obj[$scope.trackBy]] ? 1 : 0);
                }

                function allSelected(data) {
                    var numSelected = data.reduce(countSelected, 0);

                    return (numSelected === data.length && numSelected !== 0);
                }

                $scope.toggleAll = function () {
                    var newValue = !allSelected($scope.data);

                    $scope.data.forEach(function (obj) {
                        $scope.selected[obj[$scope.trackBy]] = newValue;
                    });
                    $scope.change();
                };

                $scope.allSelected = allSelected;
            }
        };
    });
