'use strict';

angular.module('webmailWrapper')
.controller(
    'EmailNewMailboxCtrl',
    function ($scope, wizardData, domainName, step, storageQuotaOptions) {
        $scope.data = wizardData;
        $scope.domainNameUnicode = domainName;
        $scope.storageQuotaOptions = storageQuotaOptions;
        $scope.forwarder = {
            additional: wizardData.mailbox.forwarderTargets.length > 0
        };

        $scope.additionalForwarderChanged = function(hasForwarders) {
            if (hasForwarders) {
                return;
            }

            wizardData.mailbox.forwarderTargets.length = 0;
        };

        step.isValid = function() {
            $scope.$broadcast('show-errors-check-validity');
            return !$scope.newEmailMailboxForm.$invalid;
        };
    }
);
