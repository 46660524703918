import 'Styles/main.scss';


import "angular/angular.js";

import './init';
import "angular-i18n/angular-locale_de-de.js";
import "angular-animate/angular-animate.js";
import "angular-bootstrap/ui-bootstrap-tpls.js";
import "angular-ui-router/release/angular-ui-router.js";
import "angular-toastr/dist/angular-toastr.tpls.js";
import "md-progress-circular/dist/mdProgressCircular.js";

import "./rpcClient.js";
import "./wizard.js";

import "./authentication/robot.js";
import "./authentication/roundcubeManager.js";
import "./authentication/securityContext.js";
import "./authentication/tokenManager.js";
import "./authentication/userContext.js";

import "./directives/btnCircleCheckbox.js";
import "./directives/formGroup.js";
import "./directives/modalClose.js";
import "./directives/paginationLimit.js";
import "./directives/parseInt.js";
import "./directives/showErrors.js";
import "./directives/splitArray.js";
import "./directives/tableSort.js";
import "./directives/toggleAll.js";
import "./directives/validateEmailList.js";
import "./directives/validateMatches.js";
import "./directives/validatePasswordComplexity.js";

import "./filter/formatMegabyte.js";

import "./email/directive/emailAddDomain.js";
import "./email/directive/validateEmailLocalPart.js";
import "./email/directive/validateEmailDisallowedPwChars.js";
import "./email/filter/emailStatus.js";
import "./email/filter/emailType.js";
import "./email/service/emailDomainSettingsManager.js";
import "./email/service/emailHelper.js";
import "./email/service/emailStorageHelper.js";
import "./email/service/spamProfiles.js";
import "./email/robot.js";
import "./email/mailboxModel.js";
import "./email/wizard.js";

import "./controllers/login.js";
import "./controllers/password.js";
import "./controllers/profile.js";
import "./controllers/topmenu.js";
import "./controllers/email/overview.js";
import "./controllers/email/delete.js";
import "./controllers/email/editExchange.js";
import "./controllers/email/editForwarder.js";
import "./controllers/email/editMailbox.js";
import "./controllers/email/new/basicData.js";
import "./controllers/email/new/confirmation.js";
import "./controllers/email/new/forwarderTargets.js";
import "./controllers/email/new/mailbox.js";
import "./controllers/email/new/spamFilter.js";

import "./services/alertManager.js";
import "./services/browserSettings.js";
import "./services/errorMapper.js";
import "./services/filterCache.js";
import "./services/RequestCanceller.js";