'use strict';

angular.module('webmailWrapper')
.controller(
    'ProfileCtrl',
    function ($scope, mailbox, spamProfiles, mailboxModel, alertManager) {
        var autoResponderTimeframe = mailbox.autoResponder.start !== null;

        $scope.spamProfiles = spamProfiles;
        $scope.mailbox = mailbox;

        if ($scope.mailbox.autoResponder.start !== null) {
            $scope.mailbox.autoResponder.start = $scope.mailbox.autoResponder.start.split('T')[0] + 'T12:00:00Z';
        }

        if ($scope.mailbox.autoResponder.end !== null) {
            $scope.mailbox.autoResponder.end = $scope.mailbox.autoResponder.end.split('T')[0] + 'T12:00:00Z';
        }

        $scope.data = {
            additionalForwarder: (mailbox.forwarderTargets.length > 0),
            datepickerOpen: {
                end: false,
                start: false
            },
            oldPassword: '',
            password: '',
            passwordRepeat: '',
            spamProfile: 0,

            get autoResponderTimeframe() {
                return autoResponderTimeframe;
            },
            set autoResponderTimeframe(value) {
                if (value) {
                    autoResponderTimeframe = true;
                    $scope.mailbox.autoResponder.start = (new Date()).toISOString().split('T')[0] + 'T12:00:00Z';
                    $scope.mailbox.autoResponder.end = (new Date()).toISOString().split('T')[0] + 'T12:00:00Z';
                } else {
                    autoResponderTimeframe = false;
                    $scope.mailbox.autoResponder.start = null;
                    $scope.mailbox.autoResponder.end = null;
                }
            }
        };

        spamProfiles.forEach(
            function(profile, index) {
                if (angular.equals(profile.values, mailbox.spamFilter)) {
                    $scope.data.spamProfile = index;
                }
            }
        );

        $scope.selectSpamProfile = function () {
            if ($scope.data.spamProfile > 0) {
                $scope.mailbox.spamFilter = spamProfiles[$scope.data.spamProfile].values;
            }
        };

        $scope.submit = function (form) {
            $scope.$broadcast('show-errors-check-validity');
            if (form.$invalid) {
                return;
            }

            if (!$scope.data.additionalForwarder) {
                $scope.mailbox.forwarderTargets = [];
            }

            var mailboxData = JSON.parse(JSON.stringify($scope.mailbox));

            if (mailboxData.autoResponder.start !== null) {
                mailboxData.autoResponder.start = mailboxData.autoResponder.start.split('T')[0] + 'T00:00:00Z';
            }

            if (mailboxData.autoResponder.end !== null) {
                mailboxData.autoResponder.end = mailboxData.autoResponder.end.split('T')[0] + 'T23:59:59Z';
            }

            mailboxModel.update(mailboxData)
            .then(
                function () {
                    alertManager.success('Postfach wurde erfolgreich aktualisiert');
                },
                function () {
                    alertManager.error('Postfach wurde nicht aktualisiert');
                }
            );
        };
    }
);
