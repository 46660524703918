'use strict';

angular
    .module('webmailWrapper')
    .filter('emailType', function () {
        var emailTypeList = {
            ImapMailbox: 'Postfach IMAP/POP3',
            Forwarder: 'Weiterleitung',
            ExchangeMailbox: 'Postfach Exchange'
        };

        return function (input) {
            return emailTypeList[input];
        };
    });
