'use strict';

angular
    .module('webmailWrapper')
    .directive('sortTable', function () {
        function resetHeading(element) {
            element.removeClass('asc');
            element.removeClass('desc');
            element.removeAttr('aria-sort');
        }

        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {},
            link: function (scope, elem, attrs, ngModelCtrl) {
                ngModelCtrl.$render = function () {
                    var obj = {
                        field: ngModelCtrl.$modelValue.field,
                        order: ngModelCtrl.$modelValue.order
                    };

                    scope.outsideChangedValue(obj);
                };

                scope.controllerChangedValue = function (obj) {
                    ngModelCtrl.$setViewValue(obj);
                };
            },
            controller: function ($scope) {
                var headings = [];
                var fieldToElement = {};
                var that = this;

                this.registerHeading = function (element, sortField) {
                    headings.push(element);
                    fieldToElement[sortField] = element;
                };

                $scope.controllerChangedValue = angular.noop;
                $scope.outsideChangedValue = function (obj) {
                    that.setSortField(fieldToElement[obj.field], obj.field, obj.order, true);
                };

                this.setSortField = function (element, field, order, outside) {
                    order = order || 'ASC';
                    order = order.toUpperCase();
                    if (order !== 'ASC' && order !== 'DESC') {
                        order = 'ASC';
                    }

                    headings.forEach(resetHeading);

                    if (!outside) {
                        var obj = {
                            field: field,
                            order: order
                        };

                        $scope.controllerChangedValue(obj);
                    }

                    if (order === 'ASC') {
                        element.removeClass('desc');
                        element.addClass('asc');
                        element.attr('aria-sort', 'ascending');
                    }
                    else {
                        element.removeClass('asc');
                        element.addClass('desc');
                        element.attr('aria-sort', 'descending');
                    }
                };
            }
        };
    })
    .directive('sortHeader', function () {
        return {
            restrict: 'A',
            require: '^sortTable',
            link: function (scope, element, attrs, sortWrapperCtrl) {
                element.addClass('sorting');
                element.bind('click', function () {
                    var order = element.hasClass('asc') ? 'DESC' : 'ASC';
                    sortWrapperCtrl.setSortField(element, attrs.sortHeader, order);
                });
                sortWrapperCtrl.registerHeading(element, attrs.sortHeader);
            }
        };
    });
