'use strict';

angular.module('webmailWrapper')
.controller(
    'EmailExchangeEditCtrl',
    function($scope, $modalInstance, mailbox, mailboxModel, spamProfiles, storageQuotaOptions, alertManager) {
        $scope.mailbox = mailbox;
        $scope.spamProfiles = spamProfiles;
        $scope.storageQuotaOptions = storageQuotaOptions;

        $scope.data = {
            spamProfile: 0,
            password: '',
            passwordRepeat: ''
        };

        spamProfiles.forEach(function(profile, i) {
            if (angular.equals(profile.values, mailbox.spamFilter)) {
                $scope.data.spamProfile = i;
            }
        });

        $scope.selectSpamProfile = function() {
            if ($scope.data.spamProfile > 0) {
                $scope.mailbox.spamFilter = spamProfiles[$scope.data.spamProfile].values;
            }
        };

        $scope.submit = function(form) {
            $scope.$broadcast('show-errors-check-validity');
            if (form.$invalid) {
                return;
            }

            mailboxModel.update(
                $scope.mailbox,
                [undefined, null, ''].indexOf($scope.data.password) < 0 ? $scope.data.password : undefined
            )
            .then(
                function() {
                    alertManager.success('Postfach wurde erfolgreich aktualisiert');
                    $modalInstance.close();
                },
                function() {
                    alertManager.error('Postfach wurde nicht aktualisiert');
                }
            );
        };
    }
);
