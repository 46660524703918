'use strict';

angular.module('webmailWrapper')
    .factory('emailHelper', function() {
        function isExternalForwarder(forwarderTargets, domainName) {
            return forwarderTargets.some(function(target) {
                var forwarderParts = target.split('@');
                return angular.isDefined(forwarderParts[1]) && forwarderParts[1] !== domainName;
            });
        }

        function getSelectedSpamProfile(spamProfiles, spamSettings) {
            var profile = spamProfiles.find(function(profile) {
                return angular.equals(profile.values, spamSettings);
            });
            if (profile) {
                return profile;
            }
            return spamProfiles[0];
        }

        return {
            isExternalForwarder: isExternalForwarder,
            getSelectedSpamProfile: getSelectedSpamProfile
        };
    });
