'use strict';

angular
    .module('webmailWrapper')
    .directive('validatePasswordComplexity', function () {
        function isComplexEnough(value) {
            if (angular.isUndefined(value) || value === '') {
                return true;
            }

            var count = 0;
            count += /[!@#$%^&*()_+";]/.test(value);
            count += /[a-z]/.test(value);
            count += /[A-Z]/.test(value);
            count += /\d/.test(value);

            return (count >= 3);
        }

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elm, attrs, ngModelCtrl) {
                ngModelCtrl.$validators.passwordComplexity = function(modelValue, viewValue) {
                    var value = modelValue || viewValue;
                    return isComplexEnough(value);
                };
            }
        };
    });
