'use strict';

angular.module('webmailWrapper')
.factory(
    'emailClient',
    function(AuthenticatedRpcClient) {
        return new AuthenticatedRpcClient('/api/email/v1/json/', 'email');
    }
)
.factory(
    'emailRobot',
    function (emailClient) {
        var robot = {
            listMailboxes: function (filter, limit, page, sort) {
                return emailClient.call('mailboxesFind', {filter: filter, limit: limit, page: page, sort: sort});
            },

            createMailbox: function (mailbox, password) {
                return emailClient.call('mailboxCreate', {mailbox: mailbox, password: password});
            },

            checkEmailAddress: function (email) {
                return emailClient.call('checkEmailAddress', {emailAddress: email});
            },

            updateMailbox: function (mailbox, password) {
                return emailClient.call('mailboxUpdate', {mailbox: mailbox, password: password});
            },

            updateSecret: function (oldSecret, newSecret) {
                return emailClient.call('mailboxSetOwnSecret', {oldSecret: oldSecret, newSecret: newSecret});
            },

            deleteMailbox: function (mailbox) {
                return emailClient.call('mailboxDelete', {emailAddress: mailbox.emailAddress});
            },

            deletePermanentlyMailbox: function (mailbox) {
                return emailClient.call('mailboxDeletePermanently', {emailAddress: mailbox.emailAddress});
            },

            oneDomainSettings: function(domainName) {
                return emailClient.call('domainSettingsFindOne', {domainName: domainName});
            }
        };

        return robot;
    }
);
