/**
 * @author Paul Yoder <paulyoder@gmail.com>
 * @license MIT
 *
 * Quelle: https://github.com/paulyoder/angular-bootstrap-show-errors
 */
(function () {
    'use strict';

    angular.module('ui.bootstrap.showErrors', [])
        .directive('showErrors', function () {
            return {
                restrict: 'A',
                require: ['showErrors', '^form'],
                link: function (scope, el, attrs, ctrls) {
                    var showErrorsCtrl = ctrls[0];
                    var formCtrl = ctrls[1];
                    var blurred = false;
                    var trackErrorEl = el[0].querySelector('[track-error]');
                    var trackError = (trackErrorEl);
                    var eventEl = trackErrorEl;

                    if (!eventEl) {
                        eventEl = el[0].querySelector('[name]');
                        var inputName = eventEl.getAttribute('name');
                        if (!inputName) {
                            throw 'show-errors element has no child input elements with a \'name\' or \'track-error\' attribute';
                        }
                    }

                    function isInvalid() {
                        if (trackError) {
                            return showErrorsCtrl.invalid;
                        }
                        else {
                            return formCtrl[inputName].$invalid;
                        }
                    }

                    eventEl.addEventListener('blur', function () {
                        blurred = true;
                        el.toggleClass('has-error', isInvalid());
                    }, trackError);
                    eventEl.addEventListener('focus', function () {
                        blurred = false;
                    }, trackError);

                    scope.$watch(function () {
                        return isInvalid();
                    }, function (invalid) {
                        if (!blurred && invalid) {
                            return;
                        }
                        el.toggleClass('has-error', invalid);
                    });

                    scope.$on('show-errors-check-validity', function () {
                        el.toggleClass('has-error', isInvalid());
                    });
                },
                controller: function () {
                    this.invalid = false;
                },
                controllerAs: '$showErrors'
            };
        })
        .directive('trackError', function () {
            return {
                restrict: 'A',
                require: ['ngModel', '^showErrors'],
                link: function ($scope, $elem, $attrs, ctrls) {
                    var ngModelCtrl = ctrls[0];
                    var showErrorsCtrl = ctrls[1];

                    showErrorsCtrl.invalid = ngModelCtrl.$invalid;

                    $scope.$watch(function () {
                        return ngModelCtrl.$invalid;
                    }, function (invalid) {
                        showErrorsCtrl.invalid = invalid;
                    });
                }
            };
        });
})();
