'use strict';

angular.module('webmailWrapper')

.factory(
    'WizardControl',
    function ($injector, $modalStack) {
        function isPromiseLike(obj) {
            return obj && angular.isFunction(obj.then);
        }

        function WizardControl(config, initData, modal) {
            var steps = config.steps
            .map(
                function(step, index) {
                    step.enabled = index === 0;
                    return step;
                }
            );

            var base = {
                initData: initData
            };

            var data = {};

            if (config.data) {
                var dataResult = $injector.invoke(config.data, config.data, base);

                if (isPromiseLike(dataResult)) {
                    dataResult.then(
                        function(newData) {
                            angular.extend(data, newData);
                        }
                    );
                } else {
                    data = dataResult;
                }
            }

            this.data = data;
            this.currentStep = 0;
            this.steps = steps;
            this.waitForResult = false;
            this.listeners = [];

            this.submit = function() {
                if (!config.submit) {
                    return;
                }

                var base = {
                    wizardData: this.data
                };

                var result = $injector.invoke(config.submit, config.submit, base);

                if (modal && isPromiseLike(result)) {
                    this.waitForResult = true;

                    result.then(
                        function() {
                            $modalStack.close($modalStack.getTop().key);
                        }
                    )
                    .finally(
                        function() {
                            this.waitForResult = false;
                        }
                        .bind(this)
                    );
                }
            };
        }

        WizardControl.prototype = {
            constructor: WizardControl,
            showStep: function(step) {
                if (!step.show) {
                    return true;
                }

                var base = {
                    wizardData: this.data
                };

                return $injector.invoke(step.show, step.show, base);
            },
            registerListener: function(listener) {
                this.listeners.push(listener);
            },
            fireEvent: function(event) {
                var self = this;
                this.listeners.forEach(function(listener) {
                    listener.call(self, event);
                });
            },
            previous: function() {
                this.fireEvent({type: 'move', direction: 'backward'});

                this.currentStep -= 1;
                // Find the next step that can be shown
                for (; this.currentStep < this.steps.length; this.currentStep--) {
                    if (this.showStep(this.steps[this.currentStep])) {
                        // Den nächsten Schritt deaktivieren
                        this.steps[this.currentStep + 1].enabled = false;
                        return;
                    }
                }
            },
            next: function() {
                var step = this.steps[this.currentStep];

                if (step.isValid && !step.isValid()) {
                    return;
                }

                if (this.isLastStep()) {
                    this.submit();
                    return;
                }

                this.fireEvent({type: 'move', direction: 'forward'});

                this.currentStep += 1;
                // Find the next step that can be shown
                for (; this.currentStep < this.steps.length; this.currentStep++) {
                    if (this.showStep(this.steps[this.currentStep])) {
                        this.steps[this.currentStep].enabled = true;
                        return;
                    }
                }
            },
            goToStep: function(stepName) {
                var newStepIndex = this.steps.findIndex(function (step) {
                    return step.name === stepName;
                });
                var step = this.steps[newStepIndex];

                if (!step.enabled) {
                    return;
                }

                // Alle zukünfitgen steps müssen deaktiviert werden
                this.steps.forEach(function(step, index) {
                    if (index > newStepIndex) {
                        step.enabled = false;
                    }
                });

                var event = {type: 'move', direction: ''};

                if (newStepIndex > this.currentStep) {
                    event.direction = 'forward';
                }
                else {
                    event.direction = 'backward';
                }

                this.fireEvent(event);

                this.currentStep = newStepIndex;
            },
            getCurrentStep: function() {
                return this.steps[this.currentStep];
            },
            isCurrentStep: function(step) {
                return this.steps[this.currentStep].name === step.name;
            },
            isFirstStep: function() {
                return this.currentStep === 0;
            },
            isLastStep: function isLastStep() {
                return this.steps.length === this.currentStep + 1;
            },
            getData: function() {
                return this.data;
            }
        };

        return WizardControl;
    }
)

.provider(
    'wizard',
    function() {
        var wizards = {};

        this.wizard = function(name, wizard) {
            var w = angular.extend({}, wizard);
            wizards[name] = w;
            return this;
        };

        this.$get = function(WizardControl) {
            return {
                getWizard: function (name, initData, modal) {
                    return new WizardControl(wizards[name], initData, modal);
                }
            };
        };
    }
)

.directive(
    'myWizard',
    function() {
        return {
            restrict: 'A',
            scope: {
                name: '@myWizard',
                initData: '=wizardInitData',
                modal: '&'
            },
            controller: function($scope, wizard) {
                var modal = !!$scope.modal();
                var data = $scope.initData || null;
                this.wizard = wizard.getWizard($scope.name, data, modal);
                this.wizardName = $scope.name;
            }
        };
    }
)

.directive(
    'wizardContent',
    function() {
        function preLink(scope, elem, attrs, wizardCtrl) {
            scope.wizard = wizardCtrl.wizard;
            scope.steps = wizardCtrl.wizard.steps;
            scope.wizardName = wizardCtrl.wizardName;
        }

        return {
            restrict: 'E',
            scope: {},
            templateUrl: require('@/views/wizard.html'),
            require: '^myWizard',
            compile: function() {
                return {
                    pre: preLink
                };
            }
        };
    }
)

.directive(
    'wizardControls',
    function() {
        return {
            restrict: 'E',
            scope: {
                lastStepButton: '@'
            },
            templateUrl: require('@/views/wizardControls.html'),
            require: '^myWizard',
            link: function(scope, elem, attrs, wizardCtrl) {
                scope.wizard = wizardCtrl.wizard;
            }
        };
    }
)

.directive(
    'wizardInnerContent',
    function($q, $http, $sce, $templateCache, $injector, $controller, $compile, $animate) {
        /**
         * Code stammt von https://github.com/angular-ui/ui-router/blob/3e06565f5d5e1973c168116e905bd2fb569abcc6/src/viewDirective.js#L135
         *
         * Bei einem AngularJS Update auf 1.3 muss das auf jeden Fall angepasst werden!
         */
        function getRenderer() {
            return {
                enter: function(element, target, cb) {
                    $animate.enter(element, null, target, cb);
                },
                leave: function(element, cb) {
                    $animate.leave(element, cb);
                }
            };
        }

        function getTemplate(widget) {
            var deferred = $q.defer();
            if (widget.template) {
                deferred.resolve(widget.template);
            } else if (widget.templateUrl) {
                var url = $sce.getTrustedResourceUrl(widget.templateUrl);

                $http.get(
                    url,
                    {cache: $templateCache}
                )
                .then(
                    function(response) {
                        deferred.resolve(response.data);
                    },
                    function() {
                        deferred.reject('could not load template');
                    }
                )
            }

            return deferred.promise;
        }

        var currentScope, currentElement, previousElement;
        var renderer = getRenderer();

        function cleanupLastView(direction) {
            if (previousElement) {
                previousElement.remove();
                previousElement = null;
            }

            if (currentScope) {
                currentScope.$destroy();
                currentScope = null;
            }

            if (currentElement) {
                if (direction) {
                    currentElement.addClass(direction);
                }

                renderer.leave(
                    currentElement,
                    function() {
                        previousElement = null;
                    }
                );

                previousElement = currentElement;
                currentElement = null;
            }
        }

        function compileStep($scope, $element, $transclude, step, wizardData, direction) {
            var templateScope = $scope.$new();

            var base = {
                $scope: templateScope,
                wizardData: wizardData,
                step: step
            };

            var resolvers = {};
            resolvers.$tpl = getTemplate(step);

            if (step.resolve) {
                angular.forEach(
                    step.resolve,
                    function(promise, key) {
                        if (angular.isString(promise)) {
                            resolvers[key] = $injector.get(promise);
                        } else {
                            resolvers[key] = $injector.invoke(promise, promise, base);
                        }
                    }
                );
            }

            $q.all(resolvers).then(
                function(locals) {
                    angular.extend(locals, base);
                    step.$template = locals.$tpl;
                    step.locals = locals;

                    var clone = $transclude(
                        templateScope,
                        function (clone) {
                            if (direction) {
                                clone.addClass(direction);
                            }

                            renderer.enter(
                                clone,
                                $element,
                                function() {
                                    if (direction) {
                                        clone.removeClass(direction);
                                    }
                                }
                            );

                            cleanupLastView(direction);
                        }
                    );

                    currentElement = clone;
                    currentScope = templateScope;
                }
            );
        }

        return {
            restrict: 'E',
            transclude: 'element',
            terminal: true,
            priority: 400,
            link: function (scope, elem, attr, ctrl, $transclude) {
                function getStep() {
                    return scope.$eval(attr.step);
                }

                var direction = 'initial';
                var wizard = scope.$eval(attr.wizard);

                wizard.registerListener(
                    function(event) {
                        direction = event.direction;
                    }
                );

                compileStep(scope, elem, $transclude, getStep(), wizard.getData(), direction);

                scope.$watch(
                    function() {
                        return getStep().name;
                    },
                    function () {
                        compileStep(scope, elem, $transclude, getStep(), wizard.getData(), direction);
                    }
                );
            }
        };
    }
)

.directive(
    'wizardInnerContent',
    function($compile, $controller) {
        return {
            restrict: 'E',
            priority: -400,
            compile: function(tElement) {
                var initial = tElement.html();

                return function (scope, $element, attr) {
                    var step = scope.$eval(attr.step);
                    if (!step) {
                        return;
                    }

                    $element.html(step.$template ? step.$template : initial);
                    var link = $compile($element.contents());

                    if (step.controller) {
                        var templateCtrl = $controller(step.controller, step.locals);

                        if (step.controllerAs) {
                            scope[step.controllerAs] = templateCtrl;
                        }

                        $element.children().data('$ngControllerController', templateCtrl);
                    }

                    link(scope);
                };
            }
        };
    }
);
