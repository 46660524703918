'use strict';

angular.module('webmailWrapper')
    .controller('EmailOverviewCtrl', function ($scope, mailboxModel, filterCache, simpleFilter, RequestCanceller) {
        $scope.pagination = {currentPage: 1, entries: 0, limit: 10};
        $scope.mailboxes = [];
        $scope.sort = {
            field: 'mailboxEmailAddressUnicode',
            order: 'ASC'
        };
        $scope.selected = {};
        $scope.filter = simpleFilter;
        // Wird derzeit nicht benutzt, wird aber vom filterCache benötigt
        $scope.filters = [];

        var editableMailboxes = [];

        filterCache.registerController('email.account', $scope);

        function normalizeFilters(simpleFilterValue) {
            var filter = {};

            if (simpleFilterValue !== '') {
                filter = {field: 'MailboxEmailAddressUnicode', value: '*' + simpleFilterValue + '*'};
            }

            return filter;
        }

        function mailboxId(mailbox) {
            return mailbox.id;
        }

        function onPageData(result) {
            // Wenn eine neue Seite geladen wird sollen keine Zeilen mehr ausgewählt sein
            $scope.selected = {};

            $scope.mailboxes = result.data;
            $scope.pagination = result.pagination;

            var allowedEditableMailboxes = ['active'];
            editableMailboxes = $scope.mailboxes.filter(function(mailbox) {
                return allowedEditableMailboxes.includes(mailbox.status);
            }).map(mailboxId);
        }

        var canceller = new RequestCanceller(mailboxModel.getList);
        function loadPage() {
            var filters = normalizeFilters($scope.filter.value);

            canceller.request($scope.pagination.limit, $scope.pagination.currentPage, filters, $scope.sort)
                .then(onPageData);
        }
        $scope.loadPage = loadPage;

        loadPage();

        $scope.$on('mailboxes.refresh', loadPage);

        $scope.arraySelectedEditable = function() {
            return Object.keys($scope.selected).filter(function(elem) {
                if (!$scope.selected[elem]) {
                    return false;
                }
                return editableMailboxes.includes(elem);
            });
        };

        function isSelected(elem) {
            return $scope.selected[elem];
        }

        $scope.arraySelected = function () {
            return Object.keys($scope.selected).filter(isSelected);
        };
    });
