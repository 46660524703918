'use strict';

angular
    .module('webmailWrapper')
    .service('errorMapper', function() {
        var map = {
            81004: 'Das bisherige Passwort wurde falsch angegeben'
        };

        this.has = function(code) {
            return code in map;
        };

        this.map = function(error) {
            if (!(error.code in map)) {
                return;
            }
            var msg = map[error.code];
            if (msg.includes('{}')) {
                msg = msg.replace('{}', error.context);
            }
            return msg;
        };
    });
