'use strict';

angular
    .module('webmailWrapper')
    .filter('formatMegabyte', function() {
        return function(value) {
            if (value === 0) {
                return 0;
            }

            return value / 1024;
        };
    });
