'use strict';

angular.module('webmailWrapper')
    .factory('emailStorageHelper', function() {
        function generateSequence(start, end, factor) {
            factor = factor || 1;

            var sequence = [];
            for (var i = start; i <= end; i++) {
                sequence.push(i * factor);
            }

            return sequence;
        }

        return {
            generateStorageQuotaOptions: function(freeStorage, assignedStorage, minimumStorage) {
                assignedStorage = assignedStorage || 0;
                minimumStorage = minimumStorage || 1;

                if (freeStorage === -1) {
                    return generateSequence(minimumStorage, 25, 1024);
                }

                var usableStorage = (assignedStorage === 0) ? freeStorage : (freeStorage + assignedStorage - (minimumStorage * 1024));
                var usable = Math.floor(usableStorage / 1024) + minimumStorage;

                return generateSequence(minimumStorage, usable, 1024);
            }
        };
    });
