'use strict';

angular
    .module('webmailWrapper')
    .directive('emailAddDomain', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elm, attrs, ngModelCtrl) {
                var domainName = attrs.emailAddDomain;
                var sliceLength = domainName.length + 1;
                ngModelCtrl.$formatters.push(function emailLocalPartFormatter(value) {
                    if (ngModelCtrl.$isEmpty(value)) {
                        return value;
                    }
                    return value.slice(0, sliceLength);
                });

                ngModelCtrl.$parsers.push(function emailLocalPartParser(value) {
                    if (ngModelCtrl.$isEmpty(value)) {
                        return value;
                    }
                    return value + '@' + domainName;
                });
            }
        };
    });
