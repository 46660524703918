'use strict';

angular.module('webmailWrapper')
    .controller('LoginCtrl', function($scope, tokenManager, $timeout, expired, browserSettings) {
        var inProgress = false;

        $scope.credentials = {email: '', password: ''};
        $scope.isInvalid = false;
        $scope.expired = expired;
        $scope.cookieEnabled = browserSettings.cookieEnabled();

        $scope.refresh = function() {
            $scope.cookieEnabled = browserSettings.cookieEnabled();
        };

        function triggerInvalidAnimation() {
            $scope.isInvalid = true;

            $timeout(function () {
                inProgress = false;
                $scope.isInvalid = false;
            }, 550);
        }

        $scope.submit = function(form) {
            if (inProgress) {
                return;
            }
            if (form.$invalid) {
                triggerInvalidAnimation();
                return;
            }
            inProgress = true;

            tokenManager.authenticate($scope.credentials.email, $scope.credentials.password)
                .catch(triggerInvalidAnimation);
        };
    });
