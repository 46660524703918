'use strict';

angular.module('webmailWrapper')

.controller(
    'EmailNewSpamFilterCtrl',
    function ($scope, wizardData, step, spamProfiles) {
        $scope.data = wizardData;
        $scope.spamProfiles = spamProfiles;

        if (wizardData.spamProfile === null) {
            wizardData.spamProfile = spamProfiles.find(function(profile) {
                return profile.name === 'weak';
            });

            wizardData.mailbox.spamFilter = wizardData.spamProfile.values;
        }

        $scope.selectSpamProfile = function(spamProfile) {
            if (spamProfile.custom) {
                return;
            }
            angular.extend(wizardData.mailbox.spamFilter, spamProfile.values);
        };

        step.isValid = function() {
            $scope.$broadcast('show-errors-check-validity');
            return !$scope.newEmailSpamFilterForm.$invalid;
        };
    }
);
