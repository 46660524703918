'use strict';

angular.module('webmailWrapper')
    .controller('EmailDeleteCtrl', function ($scope, $modalInstance, mailboxes, mailboxModel, alertManager) {
        $scope.mailboxes = mailboxes;
        $scope.data = {warningRead: false};

        $scope.submit = function (form) {
            $scope.$broadcast('show-errors-check-validity');
            if (form.$invalid) {
                return;
            }

            mailboxModel.delete(mailboxes, true).then(function () {
                alertManager.success('Mailboxen erfolgreich gelöscht');
                $modalInstance.close();
            }, function () {
                alertManager.error('Mailboxen konnten nicht gelöscht werden');
            });
        };
    });
