'use strict';

angular
    .module('webmailWrapper')
    .directive('btnCircleCheckbox', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, ngModelCtrl) {
                var activeClass = 'btn-primary fa-check-circle-o';
                var inactiveClass = 'fa-circle-o';

                elem.addClass('fa');

                //model -> UI
                ngModelCtrl.$render = function () {
                    elem.toggleClass(activeClass, ngModelCtrl.$modelValue);
                    elem.toggleClass(inactiveClass, !ngModelCtrl.$modelValue);
                };

                //ui->model
                elem.bind('click', function () {
                    ngModelCtrl.$setViewValue(elem.hasClass(activeClass) ? false : true);
                    ngModelCtrl.$render();
                });
            }
        };
    });
