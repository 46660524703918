'use strict';

angular
    .module('webmailWrapper')
    .factory('roundcubeManager', function($http) {
        var roundcubeManager = {};
        var tokenRegexLogin = /<input.+name="_token".+value="([^"]+)"/mi;
        var tokenRegex = /"request_token":"([^"]+)"/mi;

        function urlEscape(value) {
            var result = encodeURIComponent(value);
            return result.replace('%20', '+');
        }

        function urlEncode(obj) {
            var str = [];
            for (var p in obj) {
                str.push(urlEscape(p) + '=' + urlEscape(obj[p]));
            }

            return str.join('&');
        }

        function doLogin(user, password, token) {
            var data = {
                '_token': token,
                '_task': 'login',
                '_action': 'login',
                '_timezone': 'Europe/Berlin',
                '_url': '',
                '_user': user,
                '_pass': password
            };

            return $http.post('/roundcube/?_task=login', data, {
                transformRequest: urlEncode,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            });
        }

        function doLogout(token) {
            return $http.get('/roundcube/?_task=logout&_token=' + token);
        }

        function getTokenFromLoginResponse(response) {
            var token = tokenRegexLogin.exec(response.data);
            if (!token) {
                throw new Error('No match found');
            }

            return token[1];
        }

        function getTokenFromResponse(response) {
            var token = tokenRegex.exec(response.data);
            if (!token) {
                throw new Error('No match found');
            }

            return token[1];
        }

        roundcubeManager.login = function(user, password) {
            return $http.get('/roundcube/')
                .then(getTokenFromLoginResponse)
                .then(function(token) {
                    return doLogin(user, password, token);
                });
        };

        roundcubeManager.logout = function() {
            return $http.get('/roundcube/')
                .then(getTokenFromResponse)
                .then(doLogout);
        };

        return roundcubeManager;
    });
