'use strict';

angular
    .module('webmailWrapper')
    .factory('authenticationClient', function(RpcClient) {
        return new RpcClient('/api/email/v1/json/', 'email', true);
    })
    .factory('authenticationRobot', function (authenticationClient) {
        var robot = {
            getAuthenticationDetails: function() {
                return authenticationClient.call('getAuthenticationDetails', {authToken: ''});
            },

            login: function(emailAddress, password) {
                return authenticationClient.call('login', {emailAddress: emailAddress, password: password, authToken: ''});
            },

            logout: function(authToken) {
                return authenticationClient.call('logout', {authToken: authToken});
            }
        };

        return robot;
    });
