'use strict';

angular.module('webmailWrapper')
    .controller('EmailForwarderEditCtrl', function ($scope, $modalInstance, mailbox, mailboxModel, spamProfiles, alertManager, emailHelper) {
        var autoResponderTimeframe = mailbox.autoResponder.start !== null;

        $scope.forwarder = mailbox;
        $scope.spamProfiles = spamProfiles;

        if ($scope.forwarder.autoResponder.start !== null) {
            $scope.forwarder.autoResponder.start = $scope.forwarder.autoResponder.start.split('T')[0] + 'T12:00:00Z';
        }

        if ($scope.forwarder.autoResponder.end !== null) {
            $scope.forwarder.autoResponder.end = $scope.forwarder.autoResponder.end.split('T')[0] + 'T12:00:00Z';
        }

        $scope.data = {
            additionalForwarder: (mailbox.forwarderTargets.length > 0),
            spamProfile: emailHelper.getSelectedSpamProfile(spamProfiles, mailbox.spamFilter),

            get autoResponderTimeframe() {
                return autoResponderTimeframe;
            },
            set autoResponderTimeframe(value) {
                if (value) {
                    autoResponderTimeframe = true;
                    $scope.forwarder.autoResponder.start = (new Date()).toISOString().split('T')[0] + 'T12:00:00Z';
                    $scope.forwarder.autoResponder.end = (new Date()).toISOString().split('T')[0] + 'T12:00:00Z';
                } else {
                    autoResponderTimeframe = false;
                    $scope.forwarder.autoResponder.start = null;
                    $scope.forwarder.autoResponder.end = null;
                }
            }
        };
        $scope.showForwarderHint = false;

        $scope.checkForwarderTargets = function(forwarderTargets) {
            $scope.showForwarderHint = forwarderTargets && emailHelper.isExternalForwarder(forwarderTargets, mailbox.domainNameUnicode);
        };
        $scope.checkForwarderTargets(mailbox.forwarderTargets);

        $scope.selectSpamProfile = function(spamProfile) {
            if (spamProfile.custom) {
                return;
            }
            angular.extend(mailbox.spamFilter, spamProfile.values);
        };

        $scope.submit = function(form) {
            $scope.$broadcast('show-errors-check-validity');
            if (form.$invalid) {
                return;
            }

            var mailboxData = JSON.parse(JSON.stringify($scope.forwarder));

            if (mailboxData.autoResponder.start !== null) {
                mailboxData.autoResponder.start = mailboxData.autoResponder.start.split('T')[0] + 'T00:00:00Z';
            }

            if (mailboxData.autoResponder.end !== null) {
                mailboxData.autoResponder.end = mailboxData.autoResponder.end.split('T')[0] + 'T23:59:59Z';
            }

            mailboxModel.update(mailboxData)
                .then(function() {
                    alertManager.success('Weiterleitung wurde erfolgreich aktualisiert');
                    $modalInstance.close();
                }, function() {
                    alertManager.error('Weiterleitung wurde nicht aktualisiert');
                });
        };
    });
