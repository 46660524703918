'use strict';

angular.module('webmailWrapper')
    .service('spamProfiles', function($http) {
        function returnProfiles(result) {
            return result.data.profiles;
        }

        this.getProfiles = function() {
            return $http.get('/data/spamProfiles.json', {cache: true})
                .then(returnProfiles);
        };
    });
