'use strict';

angular
    .module('webmailWrapper')
    .directive('validateMatches', function () {
        function isEmpty(value) {
            return (value === '' || value === null || angular.isUndefined(value));
        }

        function compare(value1, value2) {
            if (value1 === value2) {
                return true;
            }
            if (isEmpty(value1) && isEmpty(value2)) {
                return true;
            }

            return false;
        }

        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                validateMatches: '='
            },
            link: function ($scope, elem, attrs, ngModelCtrl) {
                $scope.$watch('validateMatches', function (newValue, oldValue) {
                    if (newValue === oldValue) {
                        return;
                    }
                    ngModelCtrl.$validate();
                });

                ngModelCtrl.$validators.matches = function(modelValue, viewValue) {
                    var value = modelValue || viewValue;
                    return compare($scope.validateMatches, value);
                };
            }
        };
    });
