'use strict';

angular
    .module('webmailWrapper')
    .service('alertManager', function (toastr, errorMapper) {
        this.success = function(message, fallback) {
            if (angular.isObject(message)) {
                message = errorMapper.map(message);
            }

            toastr.success(message ? message : fallback);
        };

        this.warning = function(message, fallback) {
            if (angular.isObject(message)) {
                message = errorMapper.map(message);
            }

            toastr.warning(message ? message : fallback);
        };

        this.error = function(message, fallback) {
            if (angular.isObject(message)) {
                message = errorMapper.map(message);
            }

            toastr.error(message ? message : fallback, 'Fehler');
        };

        this.info = function(message, fallback) {
            if (angular.isObject(message)) {
                message = errorMapper.map(message);
            }

            toastr.info(message ? message : fallback);
        };
    })
    .service('rpcAlertManager', function (toastr) {
        var options = {
            timeOut: 0,
            extendedTimeOut: 0,
            closeButton: true
        };
        var robotMap = {
            account: 'Konto',
            billing: 'Rechnungs',
            domain: 'Domain',
            dns: 'DNS',
            email: 'E-Mail',
            ssl: 'SSL',
            webhosting: 'Webhosting'
        };

        this.robotNotAvailable = function(robot) {
            var message = 'Die ' + robotMap[robot] + ' API ist zur Zeit nicht erreichbar.';
            toastr.error(message, 'API nicht erreichbar', options);
        };

        this.robotInMaintenance = function(robot) {
            var message = 'Die ' + robotMap[robot] + ' API ist zur Zeit nicht erreichbar. Bitte versuchen Sie es später noch einmal.';
            toastr.error(message, 'Wartungsarbeiten', options);
        };
    });
