'use strict';

angular
    .module('webmailWrapper')
    .factory('RequestCanceller', function($q) {
        function RequestCanceller(callback) {
            this.callback = callback;
            this.cancel = null;
        }
        RequestCanceller.prototype.request = function () {
            for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
                args[_key] = arguments[_key];
            }

            if (this.cancel) {
                this.cancel.resolve();
            }
            this.cancel = $q.defer();

            return this.callback.apply(undefined, args.concat([this.cancel.promise]));
        };

        return RequestCanceller;
    });
