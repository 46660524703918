'use strict';

angular.module('webmailWrapper')

.controller(
    'EmailNewConfirmationCtrl',
    function ($scope, wizardData, step) {
        $scope.data = wizardData;

        step.isValid = function() {
            $scope.$broadcast('show-errors-check-validity');
            return !$scope.newEmailConfirmationForm.$invalid;
        };
    }
);
