'use strict';

angular
    .module('webmailWrapper')
    .directive('parseInt', function () {
        function fromUser(value) {
            if (angular.isUndefined(value)) {
                return value;
            }
            return parseInt(value, 10);
        }

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attr, ngModel) {
                ngModel.$parsers.unshift(fromUser);
            }
        };
    });
