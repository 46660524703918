'use strict';

angular
    .module('webmailWrapper')
    .factory('filterCache', function($rootScope) {
        var cache = {};

        $rootScope.$on('logout', function onLogout() {
            cache = {};
        });

        return {
            getFilter: function(stateName) {
                return cache[stateName];
            },
            setFilter: function(stateName, simpleFilter, extendedFilters) {
                cache[stateName] = {
                    simpleFilter: simpleFilter,
                    extendedFilters: extendedFilters
                };
            },
            registerController: function(stateName, scope) {
                var deregister = scope.$on('$destroy', function() {
                    cache[stateName] = {
                        simpleFilter: scope.filter.value,
                        extendedFilters: scope.filters
                    };
                    deregister();
                });
            },
            resolveExtendedFilters: function(stateName) {
                if (stateName in cache) {
                    return cache[stateName].extendedFilters;
                }

                return [];
            },
            resolveSimpleFilter: function(stateName) {
                if (stateName in cache) {
                    return {
                        show: (cache[stateName].extendedFilters.length > 0),
                        value: cache[stateName].simpleFilter
                    };
                }

                return {
                    show: false,
                    value: ''
                };
            }
        };
    });
