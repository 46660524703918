'use strict';

angular
    .module('webmailWrapper')
    .factory('securityContext', function (userContext) {
        var context = {};

        context.isGranted = function (right) {
            return userContext.getUser().rights.includes(right);
        };

        context.isGrantedAny = function (rights) {
            return rights.some(context.isGranted);
        };

        return context;
    });
