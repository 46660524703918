import "./ravenConfig.js";
import './vars';

angular
    .module('webmailWrapper', [
        'ui.router', 'ui.bootstrap', 'ui.bootstrap.showErrors', 'ngAnimate', 'toastr', 'ngRaven', 'config', 'material.components.progressCircular'
    ])
    .config(function($locationProvider) {
        $locationProvider.html5Mode(true);
    })
    .config(function($urlRouterProvider) {
        /**
         * Workaround für https://github.com/angular-ui/ui-router/issues/600
         *
         * Dies sollte eigentlich wie folgt aussehen:
         * $urlRouterProvider.otherwise('/404');
         */
        $urlRouterProvider.otherwise(function($injector) {
            $injector.get('$state').go('notFound');
        });
    })
    .config(function (RavenConfig, RavenVars) {
        RavenConfig.dsn = '//' + RavenVars.key + '@' + RavenVars.host + '/' + RavenVars.id;
    })
    .config(function (paginationConfig) {
        paginationConfig.boundaryLinks = true;
        paginationConfig.rotate = false;
        paginationConfig.firstText = '«';
        paginationConfig.previousText = '‹';
        paginationConfig.nextText = '›';
        paginationConfig.lastText = '»';
        paginationConfig.maxSize = 10;
    })
    .config(function (datepickerConfig, datepickerPopupConfig) {
        datepickerConfig.startingDay = 1; //Montag

        datepickerPopupConfig.showButtonBar = false;
    })
    .config(function (toastrConfig) {
        toastrConfig.extendedTimeOut = 1500;
        toastrConfig.positionClass = 'toast-bottom-right';
    })
    .config(function($modalProvider) {
        $modalProvider.options.backdrop = 'static';
    })
    .config(function($compileProvider) {
        $compileProvider.debugInfoEnabled(false);
    })
    .run(function($rootScope, $window) {
        $rootScope.$on('$stateChangeStart', function() {
            $window.scrollTo(0, 0);
        });
    })
    .config(function($stateProvider) {
        function getSpamProfiles(spamProfiles) {
            return spamProfiles.getProfiles();
        }

        $stateProvider
            .state('authenticated', {
                abstract: true,
                resolve: {
                    mailbox: function(userContext) {
                        return userContext.getMailbox();
                    }
                },
                views: {
                    'topmenu@': {
                        templateUrl: require('@/views/topmenu.html'),
                        controller: 'TopMenuCtrl'
                    }
                }
            })
            .state('notFound', {
                url: '/404',
                views: {
                    'wrapper@': {
                        templateUrl: require('@/views/404.html')
                    }
                },
                data: {
                    authenticate: false
                }
            })
            .state('login', {
                url: '/login?expired',
                views: {
                    'wrapper@': {
                        templateUrl: require('@/views/login.html'),
                        controller: 'LoginCtrl'
                    }
                },
                resolve: {
                    expired: function($stateParams) {
                        return $stateParams.expired;
                    }
                },
                data: {
                    authenticate: false
                }
            })
            .state('logout', {
                url: '/logout',
                views: {
                    'wrapper@': {
                        templateUrl: require('@/views/login.html'),
                        controller: 'LoginCtrl'
                    }
                },
                resolve: {
                    expired: function($stateParams) {
                        return $stateParams.expired;
                    }
                },
                data: {
                    authenticate: false
                },
                onEnter: function(tokenManager) {
                    const currentToken = tokenManager.getToken();

                    if (currentToken && currentToken.length > 0) {
                        tokenManager.logout();
                    }
                }
            })
            .state('webmailer', {
                url: '/',
                parent: 'authenticated',
                views: {
                    'wrapper@': {
                        templateUrl: require('@/views/webmailer.html')
                    }
                }
            })
            .state('email', {
                url: '/overview',
                parent: 'authenticated',
                resolve: {
                    simpleFilter: function(filterCache) {
                        return filterCache.resolveSimpleFilter('email.account');
                    }
                },
                data: {
                    isAdmin: true
                },
                views: {
                    'wrapper@': {
                        templateUrl: require('@/views/email/overview.html'),
                        controller: 'EmailOverviewCtrl'
                    }
                }
            })
            .state('email.new', {
                url: '/new',
                onEnter: function ($state, $modal) {
                    $modal.open({
                        templateUrl: require('@/views/email/new.html'),
                        size: 'lg',
                        windowClass: 'modal-xlg'
                    }).result.finally(function () {
                            $state.go('^');
                        });
                }
            })
            .state('email.edit', {
                url: '/edit/{id:[a-zA-Z0-9]{1,}}',
                resolve: {
                    mailbox: function (mailboxModel, $stateParams) {
                        return mailboxModel.findOne($stateParams.id);
                    }
                },
                onEnter: function ($state, $modal, mailbox) {
                    const tmpMailbox = mailbox;
                    if (tmpMailbox.type === 'ImapMailbox') {
                        $modal.open(
                            {
                                templateUrl: require('@/views/email/editMailbox.html'),
                                size: 'lg',
                                resolve: {
                                    mailbox: function() {
                                        return tmpMailbox;
                                    },
                                    spamProfiles: getSpamProfiles,
                                    storageQuotaOptions: function(emailDomainSettingsManager, emailStorageHelper) {
                                        return emailDomainSettingsManager.getMailboxFreeStorageQuota()
                                        .then(
                                            function(freeStorage) {
                                                return emailStorageHelper.generateStorageQuotaOptions(freeStorage, tmpMailbox.storageQuota, 1);
                                            }
                                        );
                                    }
                                },
                                controller: 'EmailMailboxEditCtrl'
                            }
                        )
                        .result.finally(
                            function () {
                                $state.go('^');
                            }
                        );
                    } else if (tmpMailbox.type === 'ExchangeMailbox') {
                        $modal.open(
                            {
                                templateUrl: require('@/views/email/editExchange.html'),
                                size: 'lg',
                                resolve: {
                                    mailbox: function() {
                                        return tmpMailbox;
                                    },
                                    spamProfiles: getSpamProfiles,
                                    storageQuotaOptions: function(emailDomainSettingsManager, emailStorageHelper) {
                                        return emailDomainSettingsManager.getMailboxFreeStorageQuota()
                                        .then(
                                            function(freeStorage) {
                                                return emailStorageHelper.generateStorageQuotaOptions(freeStorage, tmpMailbox.storageQuota, 5);
                                            }
                                        );
                                    }
                                },
                                controller: 'EmailExchangeEditCtrl'
                            }
                        )
                        .result.finally(
                            function () {
                                $state.go('^');
                            }
                        );
                    } else {
                        $modal.open(
                            {
                                templateUrl: require('@/views/email/editForwarder.html'),
                                size: 'lg',
                                resolve: {
                                    mailbox: function() {
                                        return tmpMailbox;
                                    },
                                    spamProfiles: getSpamProfiles
                                },
                                controller: 'EmailForwarderEditCtrl'
                            }
                        )
                        .result.finally(
                            function () {
                                $state.go('^');
                            }
                        );
                    }
                }
            })
            .state('email.delete', {
                url: '/delete?id',
                params: {
                    id: {array: true}
                },
                onEnter: function ($stateParams, $state, $modal) {
                    $modal.open({
                        templateUrl: require('@/views/email/delete.html'),
                        controller: 'EmailDeleteCtrl',
                        resolve: {
                            mailboxes: function (mailboxModel) {
                                return mailboxModel.findById($stateParams.id);
                            }
                        }
                    })
                    .result.finally(
                        function () {
                            $state.go('^');
                        }
                    );
                }
            })
            .state('password', {
                url: '/password',
                parent: 'authenticated',
                resolve: {
                    emailAddress: function(userContext) {
                        return userContext.getMailbox().emailAddressUnicode;
                    },
                    spamProfiles: getSpamProfiles
                },
                views: {
                    'wrapper@': {
                        templateUrl: require('@/views/password.html'),
                        controller: 'ChangePasswordCtrl'
                    }
                }
            })
            .state('profile', {
                url: '/profile',
                parent: 'authenticated',
                resolve: {
                    mailbox: function(userContext, mailboxModel) {
                        return mailboxModel.findOne(userContext.getMailbox().id);
                    },
                    spamProfiles: getSpamProfiles
                },
                views: {
                    'wrapper@': {
                        templateUrl: require('@/views/profile.html'),
                        controller: 'ProfileCtrl'
                    }
                }
            });
    })
    .run(function($rootScope, tokenManager, $state, securityContext, userContext) {
        var authenticationStatusChecked = false;

        tokenManager.checkAuthenticated().then(function () {
            authenticationStatusChecked = true;
            if (tokenManager.lastTarget.state) {
                $state.go(tokenManager.lastTarget.state.name, tokenManager.lastTarget.params);
            }
        }, function () {
            authenticationStatusChecked = true;
            $state.go('login', {expired: true});
        });


        $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
            if (!authenticationStatusChecked) {
                event.preventDefault();
                tokenManager.lastTarget = {state: toState, params: toParams};

                return;
            }

            if ((angular.isUndefined(toState.data) || angular.isUndefined(toState.data.authenticate) || toState.data.authenticate) && !tokenManager.isAuthenticated()) {
                // User isn’t authenticated. Redirect to authentication
                event.preventDefault();
                $state.go('login');
            }
            else if (tokenManager.isAuthenticated() && toState.name === 'login') {
                event.preventDefault();
                $state.go('webmailer');
            }
            else if ((angular.isDefined(toState.data) && angular.isDefined(toState.data.isAdmin))) {
                if (!userContext.getMailbox().isAdmin) {
                    event.preventDefault();
                    // @todo Zeige dem Benutzer eine Fehlermeldung
                }
            }
        });
    });
