(function () {
    'use strict';

    function requiredLink(scope, elem, attr, ctrl) {
        if (!ctrl) {
            return;
        }

        ctrl.required = attr.required;
        attr.$observe('required', function (newValue) {
            ctrl.required = newValue;
        });
    }

    function requiredDirective() {
        return {
            restrict: 'A',
            require: '?^formGroup',
            link: requiredLink
        };
    }

    angular.module('webmailWrapper')
        .directive('required', requiredDirective)
        .directive('ngRequired', requiredDirective)
        .directive('formGroup', function () {
            return {
                restrict: 'C',
                link: function (scope, elem, attrs, ctrl) {
                    if (ctrl.required) {
                        elem.addClass('required');
                    }

                    scope.$watch(function () {
                        return ctrl.required;
                    }, function (newValue) {
                        elem.toggleClass('required', newValue);
                    });
                },
                controller: function () {
                    this.required = false;
                }
            };
        });
})();
