'use strict';

angular.module('webmailWrapper')

.controller(
    'EmailNewBasicDataCtrl',
    function ($scope, wizardData, step, mailboxModel, domainName) {
        $scope.data = wizardData;
        $scope.domainName = domainName;
        $scope.isEmailAddressChecking = false;
        var checkCache = {};

        function assignCheckResult(result) {
            var validEmailAddress = true;
            var validEmailAddressTaken = true;
            var verificationNotRequired = true;

            $scope.data.verification.status = result.status;

            switch (result.status) {
                case 'emailAddressInvalid':
                    validEmailAddress = false;
                    break;

                case 'verificationRequired':
                    verificationNotRequired = false;
                    break;

                case 'emailAddressTaken':
                    validEmailAddressTaken = false;
                    break;
            }

            $scope.newEmailBasicDataForm.emailAddress.$setValidity('validEmailAddress', validEmailAddress);
            $scope.newEmailBasicDataForm.emailAddress.$setValidity('emailVerificationNotRequired', verificationNotRequired);
            $scope.newEmailBasicDataForm.emailAddress.$setValidity('emailAddressTaken', validEmailAddressTaken);
        }

        $scope.checkEmailAddress = function(emailAddress) {
            if (emailAddress in checkCache) {
                assignCheckResult(checkCache[emailAddress]);
                return;
            }

            $scope.isEmailAddressChecking = true;

            mailboxModel.emailCheck(emailAddress)
            .then(
                function(result) {
                    checkCache[emailAddress] = result;
                    assignCheckResult(result);

                    $scope.isEmailAddressChecking = false;
                }
            );
        };

        step.isValid = function() {
            if ($scope.isEmailAddressChecking) {
                return false;
            }
            $scope.$broadcast('show-errors-check-validity');
            return !$scope.newEmailBasicDataForm.$invalid;
        };
    }
);
