'use strict';

angular
    .module('webmailWrapper')
    .directive('validateEmailDisallowedPwChars', function() {
        function hasNoDisallowedChars(value) {
            if (angular.isUndefined(value) || value === '') {
                return true;
            }

            if (value.includes('§')) {
                return false;
            }

            return true;
        }

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elm, attrs, ngModelCtrl) {
                ngModelCtrl.$validators.emailDisallowedPwChars = function(modelValue, viewValue) {
                    var value = modelValue || viewValue;
                    return hasNoDisallowedChars(value);
                };
            }
        };
    });
