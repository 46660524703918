'use strict';

angular.module('webmailWrapper')
.service(
    'mailboxModel',
    function(emailRobot, $q, $rootScope) {
        function deleteOne(mailbox) {
            return emailRobot.deleteMailbox(mailbox);
        }

        function deleteOnePermanently(mailbox) {
            return emailRobot.deletePermanentlyMailbox(mailbox);
        }

        function refresh(result) {
            $rootScope.$broadcast('mailboxes.refresh');
            return result;
        }

        function idFilter(id) {
            return {field: 'mailboxId', value: id};
        }

        function returnResponseDate(result) {
            return result.response.data;
        }

        this.getList = function (limit, page, filters, sort) {
            sort = sort || {
                field: 'mailboxEmailAddressUnicode',
                order: 'ASC'
            };
            page = page || 1;

            return emailRobot.listMailboxes(filters, limit, page, sort).then(function (result) {
                return {
                    data: result.response.data,
                    pagination: {
                        currentPage: result.response.page,
                        entries: result.response.totalEntries,
                        limit: result.response.limit
                    }
                };
            });
        };

        this.findOne = function (id) {
            return emailRobot.listMailboxes({field: 'mailboxId', value: id}, 10, 1)
                .then(function (result) {
                    if (result.response.data.length) {
                        return result.response.data[0];
                    }
                    return $q.reject(new Error('Not Found'));
                });
        };

        this.findById = function (ids) {
            var filter = {subFilterConnective: 'OR', subFilter: ids.map(idFilter)};
            return emailRobot.listMailboxes(filter, 0, 1)
                .then(returnResponseDate);
        };

        this.createMailbox = function (mailbox, password) {
            return emailRobot.createMailbox(mailbox, password)
                .then(refresh);
        };

        this.emailCheck = function (email) {
            return emailRobot.checkEmailAddress(email).then(function (result) {
                return result.response;
            });
        };

        this.update  = function (mailbox, password) {
            return emailRobot.updateMailbox(mailbox, password)
                .then(refresh);
        };

        this.updateSecret = function (oldSecret, newSecret) {
            return emailRobot.updateSecret(oldSecret, newSecret);
        };

        this.delete = function (mailboxes, permantly) {
            var action = permantly ? deleteOnePermanently : deleteOne;

            var promises = mailboxes.map(action);
            return $q.all(promises)
                .then(refresh);
        };
    }
);
