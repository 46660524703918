'use strict';

angular
    .module('webmailWrapper')
    .factory('RpcClient', function ($http, $q, Raven, rpcAlertManager, errorMapper) {
        var RpcClient = function (baseUrl, robot, ignoreUnavailable) {
            this.baseUrl = baseUrl;
            this.robot = robot;
            this.ignoreUnavailable = ignoreUnavailable;
        };

        function createApiError(errObj) {
            var err = new Error(errObj.text);
            err.name = 'ApiError';
            err.code = errObj.code;
            if (errObj.context) {
                err.context = errObj.context;
            }
            return err;
        }

        function createHttpError(response) {
            var err = new Error(response.statusText);
            err.name = 'HttpError';
            err.code = response.status;
            return err;
        }

        var ignoredCodes = [
            10101, // Session invalid
            10102, // Session expired
            10103, // CSRF Token invalid
            81001, // Username or Password wrong
        ];

        function responseSuccess(response) {
            if (response.data.status === 'error') {
                if (!errorMapper.has(response.data.errors[0].code) && !ignoredCodes.includes(response.data.errors[0].code)) {
                    Raven.captureMessage(
                        'API responded with error ' + response.data.errors[0].code,
                        {
                            tags: {key: 'api', errorCode: response.data.errors[0].code, apiUrl: response.config.url},
                            extra: {apiUrl: response.config.url, method: response.config.method, requestData: response.config.data, responseData: response.data}
                        }
                    );
                }

                return $q.reject(createApiError(response.data.errors[0]));
            }

            return response.data;
        }

        var ignoredHttpCodes = [
            -1, // Network Timeout
            0, // Other Network Error
            503 // Maintenance
        ];

        function responseFailure(response) {
            // Einige Fehler müssen nicht im Sentry geloggt werden
            if (!ignoredHttpCodes.includes(response.status)) {
                Raven.captureMessage(
                    'API responded with HTTP error code ' + response.status,
                    {
                        tags: {key: 'api', statusCode: response.status, apiUrl: response.config.url},
                        extra: {apiUrl: response.config.url, method: response.config.method, requestData: response.config.data, response: response}
                    }
                );
            }

            if (response.data && response.data.status === 'error') {
                return $q.reject(createApiError(response.data.errors[0]));
            }

            return $q.reject(createHttpError(response));
        }

        function checkUnavailable(reason, robot) {
            // Check if the error means the backend is unavailable
            switch (reason.code) {
                case 10001:
                    rpcAlertManager.robotInMaintenance(robot);
                    break;
                case 10002:
                    rpcAlertManager.robotNotAvailable(robot);
                    break;
            }

            return $q.reject(reason);
        }

        RpcClient.prototype.call = function(method, data, requestConfig) {
            data = data || {};
            var config = {timeout: undefined};
            angular.extend(config, requestConfig);

            var httpConfig = {
                timeout: config.timeout,
                headers: {
                    Accept: 'application/json'
                }
            };
            var promise = $http.post(this.baseUrl + method, data, httpConfig)
                .then(responseSuccess, responseFailure);

            var self = this;
            if (!this.ignoreUnavailable) {
                promise.catch(function(reason) {
                    checkUnavailable(reason, self.robot);
                });
            }

            return promise;
        };

        return RpcClient;
    })
    .factory('AuthenticatedRpcClient', function(RpcClient, tokenManager, $q) {
        var AuthenticatedRpcClient = function (baseUrl, robot) {
            RpcClient.call(this, baseUrl, robot, false);
        };

        function createInterfaceError(message, code) {
            var err = new Error(message);
            err.name = 'InterfaceError';
            err.code = code;
            return err;
        }

        function checkExpired(reason) {
            // Check if the session has expired
            if (reason.code === 10102) {
                tokenManager.expired();
            }

            return $q.reject(reason);
        }

        AuthenticatedRpcClient.prototype = Object.create(RpcClient.prototype);
        AuthenticatedRpcClient.prototype.call = function(method, data, requestConfig) {
            data = data || {};
            var config = {
                owner: undefined
            };
            angular.extend(config, requestConfig);

            if (!tokenManager.isAuthenticated()) {
                return $q.reject(createInterfaceError('Not logged in', 1));
            }

            data.authToken = tokenManager.getToken();
            data.ownerAccountId = config.owner;

            var promise = RpcClient.prototype.call.call(this, method, data, config)
                .catch(checkExpired);

            return promise;
        };

        return AuthenticatedRpcClient;
    });
