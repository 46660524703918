'use strict';

angular
    .module('webmailWrapper')
    .directive('splitArray', function() {
        function fromUser(text) {
            if (angular.isUndefined(text) || text === '') {
                return [];
            }
            return text.split('\n');
        }

        function toUser(array) {
            if (angular.isUndefined(array)) {
                return array;
            }
            return array.join('\n');
        }

        function isEmpty(value) {
            return angular.isUndefined(value) || value.length === 0;
        }

        return {
            restrict: 'A',
            require: 'ngModel',
            priority: 100, // Diese Direktive muss früh laufen, da Validatoren möglicherweise auf das entstandende Array zugreifen möchten
            link: function(scope, element, attr, ngModel) {
                ngModel.$parsers.push(fromUser);
                ngModel.$formatters.push(toUser);

                ngModel.$isEmpty = isEmpty;
            }
        };
    });
