'use strict';

angular
    .module('webmailWrapper')
    .filter('emailStatus', function () {
        var emailStatusList = {
            creating: 'Wird erstellt',
            blocked: 'Wird aktualisiert',
            active: 'Aktiv',
            restorable: 'Wiederherstellbart'
        };

        return function (input) {
            return emailStatusList[input];
        };
    });
