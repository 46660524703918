'use strict';

angular
    .module('webmailWrapper')
    .directive('validateEmailList', function() {
        function isEmail(value) {
            if (angular.isUndefined(value) || value === '') {
                return true;
            }

            if (!value.includes('@')) {
                return false;
            }

            var parts = value.split('@');
            if (parts.length !== 2) {
                return false;
            }

            return true;
        }

        function validateEmailList(list) {
            if (angular.isUndefined(list) || list.length === 0) {
                return true;
            }
            return list.every(isEmail);
        }

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elm, attrs, ngModelCtrl) {
                ngModelCtrl.$validators.emailList = function(modelValue, viewValue) {
                    var value = modelValue || viewValue;
                    return validateEmailList(value);
                };
            }
        };
    });
