/* jshint ignore:start */

import Raven from "raven-js/dist/raven.js";
import Angular from "raven-js/dist/plugins/angular.js";

Raven
.config('//8bed10c57be942898efcafaf87b55f65@errorreport.platformadmin.de/9')
.addPlugin(Angular)
.install();
/* jshint ignore:end */
