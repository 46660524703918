'use strict';

angular
    .module('webmailWrapper')
    .service('userContext', function() {
        var mailbox = {};

        this.getMailbox = function() {
            return mailbox;
        };

        this.setMailbox= function(mailboxObj) {
            mailbox = mailboxObj;
        };
    });
