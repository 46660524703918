'use strict';

angular.module('webmailWrapper')

.controller(
    'EmailNewForwarderTargetsCtrl',
    function ($scope, wizardData, domainName, step, emailHelper) {
        $scope.data = wizardData;
        $scope.domainNameUnicode = domainName;
        $scope.showForwarderHint = false;

        $scope.checkForwarderTargets = function(forwarderTargets) {
            $scope.showForwarderHint = forwarderTargets && emailHelper.isExternalForwarder(forwarderTargets, domainName);
        };

        $scope.checkForwarderTargets(wizardData.mailbox.forwarderTargets);

        step.isValid = function() {
            $scope.$broadcast('show-errors-check-validity');
            return !$scope.newEmailForwarderTargetsForm.$invalid;
        };
    }
);
