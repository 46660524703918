'use strict';

angular
    .module('webmailWrapper')
    .factory('tokenManager', function(authenticationRobot, userContext, $state, $modalStack, $rootScope, Raven, roundcubeManager) {
        var tokenManager = {};
        var authenticated = false;
        var token = '';
        // These are error codes that should be ignored on the getAuthenticationDetails call
        var ignoredCodes = [
            10101, // Session invalid
            10102, // Session expired
            10103, // CSRF Token invalid
            10107, // API Key invalid - kann vorkommen, wenn Cookies gelöscht werden
        ];

        function setUserFromResponse(response) {
            userContext.setMailbox(response.mailbox);
            Raven.setUserContext({
                email: response.mailbox.emailAddressUnicode,
                id: response.mailbox.id
            });
        }

        function setUnauthenticated() {
            authenticated = false;
            token = '';
            var oldMailbox = userContext.getMailbox();
            userContext.setMailbox({});
            Raven.setUserContext();
            $rootScope.$emit('logout', oldMailbox);
        }

        function emitLoginAfter() {
            $rootScope.$emit('login.after');
        }

        tokenManager.lastTarget = {};

        tokenManager.expired = function() {
            setUnauthenticated();

            $modalStack.dismissAll();
            $state.go('login', {expired: true});
        };

        tokenManager.checkAuthenticated = function () {
            return authenticationRobot.getAuthenticationDetails().then(function(result) {
                authenticated = true;
                token = result.response.csrfToken;
                setUserFromResponse(result.response);
                $rootScope.$emit('login', result.response.user, result.response.account);
            }, function (reason) {
                if (ignoredCodes.includes(reason.code)) {
                    token = '';
                    authenticated = false;
                }
                else {
                    Raven.captureMessage('Unexpected error code when attempting to receive CRSF token', {tags: {key: 'csrf'}, extra: {code: reason.code}});
                    // @todo Informiere den Benutzer über den Fehler
                }
            });
        };

        tokenManager.isAuthenticated = function() {
            return authenticated;
        };

        tokenManager.authenticate = function(emailAddress, password) {
            return authenticationRobot.login(emailAddress, password)
                .then(function (result) {
                    authenticated = true;

                    token = result.response.csrfToken;
                    setUserFromResponse(result.response);
                    $rootScope.$emit('login', result.response.mailbox);

                    return roundcubeManager.login(emailAddress, password);
                })
                .then(function() {
                    var lastTarget = tokenManager.lastTarget;
                    if (lastTarget.state &&
                        lastTarget.state.name !== 'login' &&
                        lastTarget.state.name !== 'logout') {
                        $state.go(lastTarget.state.name, lastTarget.params)
                            .then(emitLoginAfter);
                    }
                    else {
                        $state.go('webmailer')
                            .then(emitLoginAfter);
                    }
                });
        };

        tokenManager.getToken = function() {
            return token;
        };

        tokenManager.logout = function () {
            return authenticationRobot.logout(token).then(function() {
                setUnauthenticated();
                return roundcubeManager.logout();
            }).then(function() {
                $state.go('login');
            });
        };

        return tokenManager;
    });
