'use strict';

angular.module('webmailWrapper')

.config(
    function(wizardProvider) {
        var getDomainName = [
            'userContext',
            function(userContext) {
                return userContext.getMailbox().domainNameUnicode;
            }
        ];

        wizardProvider.wizard(
            'newMailbox',
            {
                data: function () {
                    return {
                        mailbox: {
                            emailAddress: '',
                            forwarderTargets: [],
                            isAdmin: false,
                            spamFilter: {
                                bannedFilesChecks: false,
                                headerChecks: false,
                                malwareChecks: false,
                                spamChecks: false,
                                deleteSpam: false,
                                modifySubjectOnSpam: false,
                                spamLevel: 'low',
                                useGreylisting: false
                            },
                            storageQuota: 1024,
                            type: 'ImapMailbox'
                        },
                        password: '',
                        password2: '',
                        spamProfile: null,
                        verification: {
                            status: null
                        }
                    };
                },
                submit: [
                    'wizardData', 'mailboxModel', 'alertManager', '$q',
                    function (wizardData, mailboxModel, alertManager, $q) {
                        var newMailbox = JSON.parse(JSON.stringify(wizardData.mailbox));
                        var mailDomain = wizardData.mailbox.emailAddress.split('@')[1];

                        var forwarderTargetsIncludeExternal = wizardData.mailbox.forwarderTargets
                        .some(
                            function(target) {
                                return target.split('@')[1] !== mailDomain;
                            }
                        );

                        newMailbox.productCode
                        = {
                            ExchangeMailbox: 'email-msexchange-mailbox-1m',
                            Forwarder: forwarderTargetsIncludeExternal ? 'email-forwarder-external-12m' : 'email-forwarder-internal',
                            ImapMailbox: 'email-imap-mailbox-12m'
                        }
                        [wizardData.mailbox.type];

                        return mailboxModel.createMailbox(newMailbox, wizardData.password)
                        .then(
                            function () {
                                alertManager.success('Mailbox wurde erfolgreich angelegt');
                            },
                            function (reason) {
                                alertManager.error('Mailbox konnte nicht angelegt werden');
                                return $q.reject(reason);
                            }
                        );
                    }
                ],
                steps: [
                    {
                        name: 'basicData',
                        title: 'Grunddaten',
                        controller: 'EmailNewBasicDataCtrl',
                        templateUrl: require('@/views/email/new/basicData.html'),
                        resolve: {
                            domainName: getDomainName,
                        }
                    },
                    {
                        name: 'mailboxImap',
                        title: 'Postfach',
                        controller: 'EmailNewMailboxCtrl',
                        templateUrl: require('@/views/email/new/mailbox.html'),
                        resolve: {
                            domainName: getDomainName,
                            storageQuotaOptions: [
                                'emailDomainSettingsManager', 'emailStorageHelper',
                                function(emailDomainSettingsManager, emailStorageHelper) {
                                    return emailDomainSettingsManager.getMailboxFreeStorageQuota()
                                    .then(emailStorageHelper.generateStorageQuotaOptions);
                                }
                            ]
                        },
                        show: [
                            'wizardData',
                            function (wizardData) {
                                return wizardData.mailbox.type === 'ImapMailbox';
                            }
                        ]
                    },
                    {
                        name: 'forwarderTargets',
                        title: 'Weiterleitungsziele',
                        controller: 'EmailNewForwarderTargetsCtrl',
                        templateUrl: require('@/views/email/new/forwarderTargets.html'),
                        resolve: {
                            domainName: getDomainName
                        },
                        show: [
                            'wizardData',
                            function (wizardData) {
                                return wizardData.mailbox.type === 'Forwarder';
                            }
                        ]
                    },
                    {
                        name: 'spamFilter',
                        title: 'Spam Schutz',
                        controller: 'EmailNewSpamFilterCtrl',
                        templateUrl: require('@/views/email/new/spamFilter.html'),
                        resolve: {
                            spamProfiles: [
                                'spamProfiles',
                                function(spamProfiles) {
                                    return spamProfiles.getProfiles();
                                }
                            ]
                        }
                    },
                    {
                        name: 'confirmation',
                        title: 'Zusammenfassung',
                        controller: 'EmailNewConfirmationCtrl',
                        templateUrl: require('@/views/email/new/confirmation.html')
                    }
                ]
            }
        );
    }
);
