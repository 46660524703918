'use strict';

angular.module('webmailWrapper')
    .factory('emailDomainSettingsManager', function($rootScope, emailRobot, userContext) {
        function returnOne(result) {
            return result.response;
        }

        function doLoad() {
            return emailRobot.oneDomainSettings(userContext.getMailbox().domainName)
                .then(returnOne);
        }

        function calculateFreeStorage(storageQuota, storageQuotaAllocated) {
            if (storageQuota === -1) {
                return -1;
            }

            var freeQuota = storageQuota - storageQuotaAllocated;

            return freeQuota < 0 ? 0 : freeQuota;
        }

        function getExchangeFreeStorage(result) {
            return calculateFreeStorage(result.exchangeStorageQuota, result.exchangeStorageQuotaAllocated);
        }

        function getMailboxFreeStorage(result) {
            return calculateFreeStorage(result.storageQuota, result.storageQuotaAllocated);
        }

        return {
            getSettings: function() {
                return doLoad();
            },

            getExchangeFreeStorageQuota: function() {
                return this.getSettings()
                    .then(getExchangeFreeStorage);
            },

            getMailboxFreeStorageQuota: function() {
                return this.getSettings()
                    .then(getMailboxFreeStorage);
            }
        };
    });
