'use strict';

angular
    .module('webmailWrapper')
    .directive('validateEmailLocalPart', function() {
        function isValidLocalPart(value) {
            if (angular.isUndefined(value) || value === '') {
                return true;
            }

            /**
             * The following characters can not be allowed:
             * @ delimts the local part from the domain
             * " quoted strings, widely incompatible
             * + should work, but dovecot chokes on it
             */
            if (value.includes('@') || value.includes('"') || value.includes('+')) {
                return false;
            }

            return true;
        }

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elm, attrs, ngModelCtrl) {
                ngModelCtrl.$validators.emailLocalPart = function(modelValue, viewValue) {
                    return isValidLocalPart(viewValue);
                };
            }
        };
    });
